import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Image from "../components/common/image"

import { ChevronRightIcon } from '@heroicons/react/solid'
import { ClipboardListIcon, UserGroupIcon, DocumentAddIcon, InformationCircleIcon } from '@heroicons/react/outline'

import { trackEvent } from "../utils/tracking"


const links = [
  { title: 'Topics', link: '/topics/', description: 'Browse all community topics', icon: ClipboardListIcon },
  // { title: 'Communities', link: '/communities/', description: 'See recently added communities', icon: UserGroupIcon },
  { title: 'Suggest', link: '/suggest/', description: 'Suggest a new topic for us to curate', icon: DocumentAddIcon },
  // { title: 'Submit', link: '/submit/', description: 'Submit a new community', icon: DocumentAddIcon },
  { title: 'About', link: '/', description: 'Learn more about this site', icon: InformationCircleIcon },
]

const NotFoundPage = () => {

  useEffect(() => {
    trackEvent("404", {
      'path': document && document.location && document.location.pathname
    })
  }, []);

  return (
    <Layout pageTitle="">
      <SEO title="404 | Hive Index" />

      <div className="max-w-xl mx-auto">
        <div className="text-center">
          <p className="text-sm font-semibold text-rose-600 uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            This page does not exist.
          </h1>
          {/*<p className="mt-2 text-lg text-gray-500">The page you are looking for could not be found.</p>*/}
        </div>
        <div className="mt-12">
          <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Popular pages</h2>
          <ul role="list" className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
            {links.map((link, linkIdx) => (
              <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-rose-50">
                    <link.icon className="h-6 w-6 text-rose-700" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                    <span className="rounded-sm">
                      <Link to={link.link} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {link.title}
                      </Link>
                    </span>
                  </h3>
                  <p className="text-base text-gray-500">{link.description}</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-8">
            <Link to="/" className="text-base font-medium text-rose-600 hover:text-rose-500">
              Or go back home<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default NotFoundPage
